import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import ComingSoon from "./pages/ComingSoon";

import "./styling/App.css";
import "./styling/Pages.css";
import "./styling/Components.css";
import "./styling/fonts.css";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDCe85FTaHQq-b3LWNGx0_BjZviqxhFeqY",
  authDomain: "post-acute-bluelake.firebaseapp.com",
  projectId: "post-acute-bluelake",
  storageBucket: "post-acute-bluelake.appspot.com",
  messagingSenderId: "581310728039",
  appId: "1:581310728039:web:172f4d6be06c6bc25b91c2",
  measurementId: "G-HTTT9JG9NS",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const App = () => {
  return (
    <HelmetProvider>
      <div className="app">
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          <Route path="/Home" element={<ComingSoon />} />
        </Routes>
      </div>
    </HelmetProvider>
  );
};

export default App;
